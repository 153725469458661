<template>
  <div id="newData">
    <div class="newDataAll">
      <div class="titleHeader">
        <img src="../../../assets/img/1.4.0.2/ico_jcbs备份 2@2x.png" alt="" />
        <p>
          请您遵守国家相关规定，切勿上传低俗色情、暴力恐怖、谣言诈骗、侵权盗版等相关内容，叮叮开课保有依据国家规定及平台规则进行处理的权利。
        </p>
      </div>
      <div class="seting">
        <el-form
          label-position="left"
          label-width="100px"
          :model="ruleForm"
          :rules="rules"
          ref="ruleFormData"
          class="demo-ruleForm"
          @submit.native.prevent
        >
          <el-form-item label="资料名称" prop="name">
            <el-input
              placeholder="请输入资料名称"
              v-model="ruleForm.name"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="资料简介">
            <el-input
              type="textarea"
              placeholder="请输入资料简介"
              v-model="ruleForm.introduction"
              maxlength="140"
              show-word-limit
              :rows="4"
            ></el-input>
          </el-form-item>

          <el-form-item label="价格类型" prop="price_type">
            <div class="priceType">
              <el-radio-group
                v-model="ruleForm.price_type"
                @change="radioChange"
              >
                <el-radio label="1">付费</el-radio>
                <el-radio label="2">免费</el-radio>
                <el-radio label="3">密码</el-radio>
              </el-radio-group>

              <el-form-item
                label="价格"
                v-if="ruleForm.price_type == 1"
                prop="price"
              >
                <el-input
                  placeholder="请输入价格"
                  :value="ruleForm.price"
                  @input="ruleForm.price = toNumber(arguments[0])"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="划线价"
                v-if="ruleForm.price_type == 1 || ruleForm.price_type == 2"
                prop="crossed_price"
              >
                <el-input
                  placeholder="请输入划线价，选填"
                  :value="ruleForm.crossed_price"
                  @input="ruleForm.crossed_price = toNumber(arguments[0])"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="密码"
                prop="pwd"
                v-if="ruleForm.price_type == 3"
              >
                <el-input
                  v-model.trim="ruleForm.pwd"
                  maxlength="8"
                  placeholder="请输入8位数内获取密码"
                ></el-input>
              </el-form-item>
            </div>
            <div
              v-if="ruleForm.price_type == 2 || ruleForm.price_type == 3"
              class="sigup-button-wrap"
              style="
                paddin-top: 0;
                padding-bottom: 0;
                white-space: nowrap;
                padding-left: 0;
              "
            >
              <el-form-item label="报名按钮" prop="costpriceFree">
                <el-input
                  style="width: 176px"
                  :placeholder="ruleForm.price_type == 2 ? '免费' : '密码获取'"
                  type="text"
                  maxlength="6"
                  show-word-limit
                  v-model.trim="ruleForm.button_name"
                />
              </el-form-item>
            </div>
          </el-form-item>
          <el-form-item label="封面" prop="photo">
            <div class="course-pic-wrap">
              <img :src="ruleForm.img_url" alt="" />
              <div class="p-wrap">
                <p class="txt">建议尺寸800*450px，JPG、PNG格式， 图片小于5M</p>
                <div class="btn-wrap">
                  <div class="btnchangePhotoimg" plain>更换封面</div>
                  <changePhotoimg
                    :size="5"
                    @complete="completechangePhotoimg"
                    class="changePhotoimg"
                  ></changePhotoimg>
                  <!-- <el-button type="info" plain class="btnchangePhotoimg">
                    更换封面
                  </el-button> -->
                  <!-- </uploadImg> -->
                  <span class="warning" v-if="isShowWarning">图片过大</span>
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="资料详情" prop="menbership">
            <editor class="editor" v-model="ruleForm.content"></editor>
          </el-form-item>

          <el-form-item label="展示到网校">
            <div class="flexBox">
              <el-switch
                v-model="ruleForm.is_show"
                active-color="#1B9D97"
              ></el-switch>
              <div class="schoolstyle schoolstyle_1">
                开启后资料信息会在网校展示，学员可自行购买；隐藏后不会在网校显示资料信息，只有已购买才能查看，或通过链接、二维码进入查看
              </div>
            </div>
          </el-form-item>

          <el-form-item label="展示已售数量">
            <div class="flexBox">
              <el-switch
                v-model="ruleForm.is_show_sales_num"
                active-color="#1B9D97"
              ></el-switch>
              <div class="schoolstyle">
                显示已售次数有助于提升资料的热度，可自定义输入；学员端展示已售次数和浏览次数都会加上自定输入的次数
              </div>
            </div>
            <div
              style="height: 40px; width: 20px; margin-top: 20px"
              v-if="!ruleForm.is_show_sales_num"
            ></div>
            <div class="down_sum" v-if="ruleForm.is_show_sales_num">
              <div>
                <p>真实已售次数：{{ ruleForm.sales_num }}</p>
              </div>
              <div class="inputBox is">
                <p>自定义次数</p>
                <el-input
                  type="phone"
                  maxlength="8"
                  v-model="ruleForm.sales_base_num"
                  :onkeyup="
                    (ruleForm.sales_base_num = ruleForm.sales_base_num.replace(
                      /\D/g,
                      ''
                    ))
                  "
                ></el-input>
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="btns">
          <el-button type="primary" @click="preservation">保存</el-button>
          <p>提示：保存后会进入管理页，可进行添加资料内容和管理课程</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import changePhotoimg from '@cm/base/changePhotoimg'
import editor from '@cm/editor'
export default {
  // mixins: [bg],
  name: 'newData',
  data() {
    // 验证密码
    const yzPassword = (rule, value, callback) => {
      if (!/^[a-zA-Z0-9]{1,8}$/.test(value)) {
        callback(new Error('请输入数字或者字母'))
      } else {
        callback()
      }
    }
    return {
      // 图片上传过大
      isShowWarning: false,
      spec: {},
      existence: '', //判断是否有自定义的图
      token: '',
      // 编辑器课程详情
      content: '',
      course_ids: '', //选择的课程id
      //基础信息
      ruleForm: {
        pwd: '',
        name: '',
        introduction: '',
        cover_img: '',
        is_show: true,
        content: '',
        price_type: '1',
        is_show_sales_num: true,
        price: '',
        crossed_price: '',
        img_url:
          'https://img.dingdingkaike.com/FsyMow3Tt8MrJzPY-cNjrSgs9_3l?imageView2/1/w/828/h/466',
        sales_base_num: '',
        sales_num: '0',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入资料名称',
            trigger: 'blur',
          },
        ],

        price_type: [
          {
            required: false,
            message: '请选择收费方式',
            trigger: 'change',
          },
        ],
        pwd: [
          {
            required: true,
            message: '请输入密码',
            trigger: 'blur',
          },
          {
            validator: yzPassword,
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入价格',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  computed: {
    title() {
      return this.$store.state.userInfo.jg_title
    },
  },
  mounted() {
    if (this.$route.params.id != 'new') {
      this.getdata()
      this.$root.$children[0].childPageOptions[
        this.$root.$children[0].childPageOptions.length - 1
      ].name = '编辑'
    }
  },
  components: {
    // uploadImg,
    editor,
    changePhotoimg,
  },

  methods: {
    completechangePhotoimg(val) {
      this.ruleForm.img_url = this.$options.filters.imageslim(val, 3)
    },
    radioChange() {
      // console.log(this.$refs.ruleFormData.clearValidate)
      var arr = {
        props: 'pwd',
      }
      this.$refs.ruleFormData.clearValidate(arr)
    },
    // 验证金额
    toNumber(value) {
      let newValue = value.match(/^[0-9]+(?:\.?\d{0,2})?/g)
      newValue = newValue ? newValue[0] : ''
      let formattedValue = newValue
      if (newValue) {
        if (newValue.indexOf('.') > 7) {
          formattedValue =
            newValue.split('.')[0].slice(0, 7) +
            '.' +
            newValue.split('.')[1].slice(0, 2)
        } else {
          formattedValue = newValue.slice(
            0,
            newValue.indexOf('.') === -1 ? 7 : newValue.indexOf('.') + 3
          )
        }
      }
      return formattedValue
    },

    getdata() {
      this.$http({
        url: '/electronicsData/editCourseBase?ed_id=' + this.$route.params.id,
        // data: {
        //   id: this.$route.params.id,
        // },
        callback: ({ data }) => {
          this.ruleForm = data
          if (data.is_show == 1) {
            this.ruleForm.is_show = true
          } else {
            this.ruleForm.is_show = false
          }
          if (data.is_show_sales_num == 1) {
            this.ruleForm.is_show_sales_num = true
          } else {
            this.ruleForm.is_show_sales_num = false
          }
        },
      })
    },
    //保存内容
    preservation() {
      this.$refs.ruleFormData.validate(valid => {
        if (valid) {
          // alert('submit!');
        } else {
          // console.log('error submit!!');
          return false
        }
      })
      if (!this.ruleForm.name.trim()) {
        this.$root.prompt({
          msg: '请输入资料名称',
        })
        return
      }

      if (this.ruleForm.price_type == 1 && this.ruleForm.price == '') {
        this.$root.prompt({
          msg: '请输入价格',
        })
        return
      }

      if (this.ruleForm.price_type == 1 && this.ruleForm.price == 0) {
        this.$root.prompt({
          msg: '请输入有效的价格',
        })
        return
      }

      if (this.ruleForm.price_type == 3 && this.ruleForm.pwd == '') {
        this.$root.prompt({
          msg: '请输入密码',
        })
        return
      }
      if (
        this.ruleForm.price_type == 3 &&
        this.ruleForm.pwd &&
        !/^[a-zA-Z0-9]{1,8}$/.test(this.ruleForm.pwd)
      ) {
        this.$root.prompt('请输入数字或者字母')
        return
      }

      let post = {}
      post.name = this.ruleForm.name
      post.img_url = this.ruleForm.img_url
      post.introduction = this.ruleForm.introduction
      post.price_type = this.ruleForm.price_type
      post.pwd = this.ruleForm.price_type == 3 ? this.ruleForm.pwd : ''
      post.price = this.ruleForm.price_type == 1 ? this.ruleForm.price : ''
      post.crossed_price =
        this.ruleForm.price_type == 1 || this.ruleForm.price_type == 2
          ? this.ruleForm.crossed_price
          : ''
      post.content = this.ruleForm.content
      post.is_show = this.ruleForm.is_show ? 1 : 2
      post.is_show_sales_num = this.ruleForm.is_show_sales_num ? 1 : 2
      post.sales_base_num = this.ruleForm.is_show_sales_num
        ? this.ruleForm.sales_base_num
        : ''
      // 默认的按钮名字
      post.button_name = this.ruleForm.button_name

      // return
      if (this.$route.params.id != 'new') {
        post.ed_id = this.$route.params.id
        this.$http({
          url: '/electronicsData/editCourseBase',
          // ed_id:this.$route.params.id,
          data: post,
          callback: () => {
            this.$router.replace({
              path: '/courseElectronics/editor/' + this.$route.params.id,
            })
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      } else {
        this.$http({
          url: '/electronicsData/createElectronics',
          data: post,
          callback: ({ data }) => {
            this.$root.prompt({
              msg: '保存成功',
              type: 'success',
            })
            this.$router.replace({
              path: '/courseElectronics/editor/' + data,
              // query: {
              //   id: data
              // },
            })
          },
          error: ({ info }) => {
            this.$root.prompt(info)
          },
        })
      }
    },
    // 获取修改后的封面图
    getResultImg(img_url) {
      this.ruleForm.img_url = img_url
    }, // 上传图片过大
    errorHappen(flag) {
      this.isShowWarning = flag
    },
    printLog(title, info) {
      window.console && console.log(title, info)
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label {
  color: #333333;
}

.down_sum {
  margin-top: 20px;
  display: flex;

  p {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 40px;
  }

  .is {
    margin-left: 40px;
  }

  .inputBox {
    p {
      width: 100px;
    }

    display: flex;
    width: 300px;
  }
}

.flexBox {
  margin-top: 10px;
  display: flex;

  .schoolstyle {
    margin-left: 10px;
    margin-top: -2px;
  }

  .schoolstyle_1 {
    margin-top: -3px;
  }
}

.priceType {
  width: 360px;

  .el-form-item {
    margin-bottom: 15px;

    ::v-deep .el-form-item__label {
      // width: 70px !important;
    }

    ::v-deep .el-form-item__content {
      // margin-left: 70px !important;
      margin-top: 5px;
    }
  }
}

// ::v-deep .pcWxUploadBtn {
//   left: 159px;
//   width: 100%;
// }

::v-deep .showUploadPs {
  width: 260px;
  left: 160px;
}

.fontcolor_green {
  ::v-deep .el-button {
    border-color: #1b9d97;
    color: #1b9d97;
  }
}

.fengxiang {
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  text-align: center;
  margin: 115px 0 138px;
}

.dialogVisible_main {
  width: 280px;
  margin: 0 auto;
  position: relative;

  .qrcode {
    box-sizing: border-box;
    overflow: hidden;
    padding: 5px;
    height: 118px;
    width: 118px;
    background-color: #fff;
    position: absolute;
    top: 49px;
    left: 50%;
    transform: translateX(-50%);
  }

  .font1 {
    height: 92px;
    width: 100%;
    position: absolute;
    top: 187px;

    .font_first {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: #ffffff;
      line-height: 18px;
    }

    .font_scond {
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
      margin: 12px 0 25px;
    }

    .font_third {
      text-align: center;
      font-size: 12px;
      color: #ffffff;
      line-height: 12px;
    }
  }

  .btns {
    display: flex;
    justify-content: center;

    a {
      text-decoration: none;
      color: #0aa29b;
    }
  }

  .result-pic {
    width: 280px;
    height: 314px;
    position: absolute;
    top: 0px;
    left: 0px;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

::v-deep .el-tabs__nav-wrap:after {
  height: 0;
}

::v-deep .el-popconfirm__action {
  margin-top: 10px !important;
}

::v-deep .el-select .el-input .el-select__caret {
  height: 36px;
}

.danwei {
  font-size: 14px;
  color: #333333;
  line-height: 14px;
  margin-left: 10px;
}

::v-deep .el-form-item {
  margin-bottom: 30px;
}

.w-e-text-container {
  height: 500px !important;
}

#newData {
  background-color: #f5f5f5;
  display: flex;

  .newDataAll {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .titleHeader {
      margin: 20px;
      height: 40px;
      background: #fff1de;
      border-radius: 2px;
      border: 1px solid #f9dbb3;
      display: flex;

      img {
        width: 13px;
        height: 14px;
        margin: 14px;
      }

      p {
        font-size: 12px;
        color: #816440;
        line-height: 40px;
      }
    }
  }

  .minwidth {
    min-width: 683px;
  }

  .seting {
    position: relative;
    width: 720px;
    // width: calc(100% - 343px);
    background-color: #fff;
    padding: 20px;

    ::v-deep .el-tabs__content {
      padding: 10px 20px 20px;
    }

    .seting_font1 {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      line-height: 16px;
      margin: 20px 0px 30px 20px;
    }

    .schoolstyle {
      font-size: 12px;
      color: #666666;
      line-height: 14px;
    }

    .editor {
      margin-bottom: 40px;
    }

    .btns {
      margin-bottom: 20px;
      bottom: 0;
      display: flex;

      p {
        width: 324px;
        font-size: 12px;
        color: #ff3535;
        line-height: 40px;
      }

      .el-button {
        width: 126px;
        margin-right: 20px;
      }
    }

    .Specifications {
      display: flex;

      .left {
        width: 112px;
        display: flex;

        .font1 {
          margin-left: 3px;
          font-size: 14px;
          color: #333333;
          line-height: 19px;
        }
      }

      .right {
        width: calc(100% - 112px);

        .format {
          position: relative;
          margin-bottom: 20px;
          width: 404px;
          height: 336px;
          background: #f5f5f5;
          padding: 20px;
          overflow: hidden;
          box-sizing: border-box;

          ::v-deep .el-form-item {
            margin-bottom: 17px;
          }

          .close {
            position: absolute;
            top: 5px;
            right: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .membershipCourse {
      .header {
        display: flex;
        align-items: center;

        .font1 {
          font-size: 12px;
          color: #666666;
          line-height: 16px;
          margin-left: 20px;
        }
      }

      .main {
        .empty {
          font-size: 12px;
          color: #666666;
          text-align: center;
          line-height: 16px;
          margin-top: 169px;
        }

        .main_course {
          margin-top: 30px;
          margin-bottom: 60px;
        }
      }
    }

    .btns2 {
      margin-left: 113px;
      margin-bottom: 95px;

      .btns2_font {
        font-size: 13px;
        color: #999999;
        margin-left: 20px;
        line-height: 13px;
      }
    }
  }
}

.course-pic-wrap {
  width: 518px;
  display: flex;
  justify-content: space-between;

  img {
    width: 220px;
    height: 124px;
  }

  .p-wrap {
    .txt {
      margin-bottom: 22px;
      font-size: 12px;
      color: rgba(102, 102, 102, 1);
      line-height: 16px;
    }

    .btn-wrap {
      position: relative;
      .changePhotoimg {
        position: absolute;
        height: 40px !important;
        position: absolute;
        top: 0;
      }
      .btnchangePhotoimg {
        border: 1px #d3d4d6 solid;
        position: absolute;
        top: 0;
        padding: 0 20px;
        background-color: #f4f4f5;
        font-size: 14px;
        color: #909399;
        border-radius: 4px;
        cursor: pointer;
      }
      .warning {
        margin-left: 10px;
        font-size: 12px;
        color: rgba(255, 53, 53, 1);
        line-height: 16px;
      }
    }
  }
}
</style>
